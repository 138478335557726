.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-card {
  background-color: #ffffff;
  width: fit-content;
  min-width: 1000px;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
}

@media screen and (max-width: 900px) {
  .content {
    padding: 20px;
  }

  .form-card {
    width: 100%;
    min-width: 0px;
  }

  .table-content {
    overflow: auto;
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#info {
  color: #8f8f8f;
  margin: 20px 0;
}

.fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

input {
  height: 30px;
}

input, textarea {
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 5px;
  font-size: inherit;
}

textarea {
  white-space: pre-wrap;
}

*:focus {
  outline: none;
}

.custom-fields {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-input-qtd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.input-qtd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.less, .plus {
  font-weight: bold;
  font-size: 16px;
}

.less, .plus, .product-name {
  margin: 0 10px;
}

.less {
  color: red;
  cursor: pointer;
}

.plus {
  color: green;
  cursor: pointer;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.value input {
  width: 70px;
}

.product-name {
  height: 100%;
}

button {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 10px;
}

.confirm-order, .send-all {
  background: #164aab;
  color: #fff;
  border: none
}

.save-order {
  background: #16ab2f;
  color: #fff;
  border: none
}

.go-back {
  border: 1px solid #F06656;
  color: #F06656;
  background-color: transparent;
}

.send-all {
  width: fit-content;
}

.confirm-order {
  background: #164aab;
  color: #fff;
  border: none
}

.save-order {
  background: #16ab2f;
  color: #fff;
  border: none
}

.go-back {
  border: 1px solid #F06656;
  color: #F06656;
  background-color: transparent;
}

.edit-order {
  background: transparent;
  color: #F06656;
  border: 1px solid #F06656;
}

.edit-icon, .send-icon {
  margin: 0 10px;
}

.edit-icon {
  color: #5a66ff;
}

.send-icon {
  color: #389538;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn {
  margin-bottom: 20px;
  text-decoration: underline;
}

.order-item {
  padding: 15px;
  background: #c8c8c8;
  margin-bottom: 10px;
  border-radius: 10px;
}

.mini-label {
  font-size: 12px !important;
  font-weight: bold !important;
}

.orders-scroll-list {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  max-height: 100px;
  max-width: 300px;
  overflow-x: auto;
  border-radius: 6px;
}

.border-bottom {
  border-bottom: 1px solid #c3c3c3;
}

.table-item-list {
  padding: 10px 5px;
}