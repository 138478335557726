@font-face {
  font-family: 'nunito';
  src: url('./assets/fonts/Nunito-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  height: 100%;
  width: 100%;
  background: #F06656;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: inherit;
}