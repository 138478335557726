.content-centered {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.content-cart {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

@media screen and (min-width: 767px) {
  .content-cart {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 900px) {
  .content-cart {
    padding: 0 10rem;
  }
}

@media screen and (min-width: 1200px) {
  .content-cart {
    padding: 0 12rem;
  }
}

@media screen and (min-width: 1400px) {
  .content-cart {
    padding: 0 25rem;
  }
}

@media screen and (min-width: 1600px) {
  .content-cart {
    padding: 0 35rem;
  }
}

@media screen and (min-width: 1800px) {
  .content-cart {
    padding: 0 45rem;
  }
}

.header-bar {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: #F06656; */
}

#logo-villa {
  width: 100px;
}

.section, .confirmed-order {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
}

.confirmed-order {
  margin-top: 100px;
  align-items: center;
}

.cart-card {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
}

h2 {
  font-weight: bold;
}

.table-item {
  padding: 20px 0px;
  border-bottom: 1px solid #c3c3c3;
}

.last-table-item {
  border-bottom: none !important;
}

.item-identifier, .item-identifier .key, .item-data .key {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-data {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.item-data .key {
  padding-top: 10px;
}

.item-data .value {
  text-align: right;
  font-weight: bold;
}

.delete-icon {
  color: red;
  font-size: 20px;
}

.actions {
  display: flex;
  flex-direction: column;
}

.actions .button-icon {
  margin-top: 10px;
}

.actions button {
  border: none;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
}

#send-button {
  color: #fff;
  background: #F06656;
}

#sales-button {
  background: #fff;
  color: #F06656;
  border: 1px solid #F06656;
}